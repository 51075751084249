import Industry from "../views/01-Industry/Industry.vue";
import Vue from "vue";
import VueRouter from "vue-router";
Vue.use(VueRouter);
const routes = [
  { path: "/market/industry", component: Industry }
  //{ path: "/market/search", component: Industry }
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes
});

export default router;
