<template>
  <div>
    <div class="container-title-top-content">
      <span>Top 3 Hashtags</span>
      <i class="fa fa-info-circle" id="top-hashtags-title"></i>
      <CpTooltipWhiteCustom
        target="top-hashtags-title"
        title="top-hashtags-title"
        paddingContainer="10px 1px 10px 1px"
        message="Due to insufficient data from the industry, it will not be possible to show the complete top 3 hashtags."
      />
    </div>
    <div class="container-card-top-hashtags">
      <card-top-hashtags :posts="posts"></card-top-hashtags>
    </div>
  </div>
</template>

<script>
import CardTopHashtags from "../../cards/cardTopHashtags/CardTopHashtags.vue";
import { CpTooltipWhiteCustom } from "@isc/styleguide";

export default {
  name: "TopHashtags",
  components: {
    CardTopHashtags,
    CpTooltipWhiteCustom
  },
  props: {
    posts: {
      type: Array,
      default: function() {
        return [];
      }
    }
  }
};
</script>

<style lang="scss" scoped>
.container-title-top-content {
  font-family: Oxygen;
  font-size: 15px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.33;
  letter-spacing: normal;
  color: #2c3852;
  padding-left: 50px;
  margin-left: 1em;
  padding-top: 2em;
  font-weight: bold;

  span {
    padding-right: 0.5rem;
  }

  i {
    color: #a2acc4;
    font-size: 13px;
  }
}
.container-card-top-hashtags {
  padding-top: 1rem;
}
</style>
