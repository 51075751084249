var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", [
    _c(
      "div",
      {
        directives: [
          {
            name: "show",
            rawName: "v-show",
            value: _vm.posts.length == 0,
            expression: "posts.length == 0"
          }
        ],
        staticClass: "container image-container-off"
      },
      [
        _c("img", {
          staticClass: "img-default img-default-hashtag",
          attrs: { src: require("../../../assets/img/hashtag.png") }
        }),
        _c("div", { staticClass: "image-container-off__message" }, [
          _c("div", [_vm._v(_vm._s(_vm.titleImgOff))]),
          _vm._v(" " + _vm._s(_vm.msgImgOff) + " ")
        ])
      ]
    ),
    _c(
      "div",
      {
        directives: [
          {
            name: "show",
            rawName: "v-show",
            value: _vm.posts.length != 0,
            expression: "posts.length != 0"
          }
        ],
        staticClass: "container"
      },
      _vm._l(_vm.posts, function(post, key) {
        return _c("div", { key: key, staticClass: "container_post" }, [
          _c("div", { staticClass: "container_post_hashtag" }, [
            _c("div", { staticClass: "container_post_hashtag-container" }, [
              _c(
                "div",
                { staticClass: "container_post_hashtag-container_id" },
                [_vm._v(_vm._s(key + 1))]
              )
            ]),
            _c("div", { staticClass: "container_post_hashtag-container2" }, [
              _c(
                "div",
                { staticClass: "container_post_hashtag-container2_title" },
                [_vm._v(" #" + _vm._s(post.key) + " ")]
              )
            ])
          ])
        ])
      }),
      0
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }