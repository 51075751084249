var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _vm.visibilityLoader === true ? _c("loader-custom") : _vm._e(),
      _c("selected-panel-global", {
        attrs: { dates: _vm.dates, geoDrivers: _vm.geoDrivers },
        on: { sendData: _vm.sendData }
      }),
      _c(
        "div",
        [
          _c("top-posts-twitter", { attrs: { posts: _vm.dataTopPost } }),
          _c("top-hashtags", { attrs: { posts: _vm.dataHashtags } }),
          _c("audience-growth-twitter", {
            attrs: { posts: _vm.dataAudience, dates: _vm.dates }
          })
        ],
        1
      ),
      _c(
        "div",
        [
          _c("IscModalAlerts", {
            attrs: {
              icon: _vm.icon,
              iconColor: _vm.iconColor,
              title: _vm.title,
              paragraph: _vm.paragraph,
              buttonMessage: _vm.buttonMessage
            },
            on: { sendResult: _vm.resultModalAlerts },
            model: {
              value: _vm.showAlert,
              callback: function($$v) {
                _vm.showAlert = $$v
              },
              expression: "showAlert"
            }
          })
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }