var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", [
    _c("div", { staticClass: "container-header d-flex w-100" }, [
      _c("div", { staticClass: "container-header_title" }, [
        _c("p", { staticClass: "container-header_title-text" }, [
          _vm._v(_vm._s(_vm.title))
        ])
      ]),
      _c(
        "div",
        { staticClass: "d-flex container-header_date" },
        [
          _c("v-datepicker", {
            ref: "datePicker",
            attrs: {
              range: "",
              type: "date",
              clearable: false,
              "range-separator": " - ",
              format: "DD/MM/YYYY",
              "disabled-date": _vm.disabledAfter
            },
            on: { change: _vm.changeDate, open: _vm.openCalendar },
            model: {
              value: _vm.rangeDate,
              callback: function($$v) {
                _vm.rangeDate = $$v
              },
              expression: "rangeDate"
            }
          })
        ],
        1
      )
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }