import { render, staticRenderFns } from "./Industry.vue?vue&type=template&id=089c27bd&scoped=true&"
import script from "./Industry.vue?vue&type=script&lang=js&"
export * from "./Industry.vue?vue&type=script&lang=js&"
import style0 from "./Industry.scss?vue&type=style&index=0&id=089c27bd&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "089c27bd",
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/Users/salmuz/Documents/Dev/Git/Monitor/mf-front/isc-market-trends/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('089c27bd')) {
      api.createRecord('089c27bd', component.options)
    } else {
      api.reload('089c27bd', component.options)
    }
    module.hot.accept("./Industry.vue?vue&type=template&id=089c27bd&scoped=true&", function () {
      api.rerender('089c27bd', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/views/01-Industry/Industry.vue"
export default component.exports