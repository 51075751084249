var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", [
    _c("div", { staticClass: "container-title-top-content" }, [
      _vm._v(" Top 3 Tweets ")
    ]),
    _c(
      "div",
      {
        directives: [
          {
            name: "show",
            rawName: "v-show",
            value: _vm.posts.lenght == 0,
            expression: "posts.lenght == 0"
          }
        ]
      },
      [
        _c("img", {
          attrs: { src: require("../../../assets/img/off-top-post.png") }
        })
      ]
    ),
    _c(
      "div",
      [
        _c("card-top-post", {
          attrs: {
            posts: _vm.posts,
            shared: false,
            likes: true,
            unlikes: false,
            comments: false,
            views: false,
            retweet: true,
            network: "tw"
          }
        })
      ],
      1
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }