import { render, staticRenderFns } from "./TopHashtags.vue?vue&type=template&id=42ab336a&scoped=true&"
import script from "./TopHashtags.vue?vue&type=script&lang=js&"
export * from "./TopHashtags.vue?vue&type=script&lang=js&"
import style0 from "./TopHashtags.vue?vue&type=style&index=0&id=42ab336a&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "42ab336a",
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/Users/salmuz/Documents/Dev/Git/Monitor/mf-front/isc-market-trends/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('42ab336a')) {
      api.createRecord('42ab336a', component.options)
    } else {
      api.reload('42ab336a', component.options)
    }
    module.hot.accept("./TopHashtags.vue?vue&type=template&id=42ab336a&scoped=true&", function () {
      api.rerender('42ab336a', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/components/content/twitter/TopHashtags.vue"
export default component.exports