<template>
  <div>
    <div class="container-header d-flex w-100">
      <div class="container-header_title">
        <p class="container-header_title-text">{{ title }}</p>
      </div>
      <div class="d-flex container-header_date">
        <v-datepicker
          range
          v-model="rangeDate"
          ref="datePicker"
          type="date"
          :clearable="false"
          range-separator=" - "
          format="DD/MM/YYYY"
          @change="changeDate"
          @open="openCalendar"
          :disabled-date="disabledAfter"
        />
      </div>
    </div>
  </div>
</template>

<script>
import moment from "moment";
import { Helpers } from "@isc/styleguide";
export default {
  name: "HeaderModule",
  props: {
    title: String
  },
  data() {
    return {
      rangeDate: [
        moment()
          .subtract(15, "days")
          .toDate(),
        moment().toDate()
      ],
      prevRangeDate: []
    };
  },
  methods: {
    disabledAfter(date) {
      return date > new Date();
    },
    async changeDate(date) {
      this.rangeDate[0] = date[0];
      this.rangeDate[1] = date[1];
      const validateRange = Helpers.validateRange(
        this.rangeDate[0],
        this.rangeDate[1]
      );
      if (!validateRange) {
        this.rangeDate = this.prevRangeDate;
        let message = "You can only search up to the last 30 days.";
        let title = "Error";
        Helpers.getToastAlert(
          this.$bvToast,
          message,
          title,
          null,
          "errorBvToast"
        );
      } else {
        this.$emit("changeDate", {
          rangeDays: Helpers.getRangeDays(this.rangeDate[0], this.rangeDate[1]),
          date
        });
      }
    },
    openCalendar() {
      this.prevRangeDate = this.rangeDate;
    }
  },
  mounted() {
    this.$emit("changeDate", { rangeDays: 16, date: this.rangeDate });
  }
};
</script>

<style lang="scss" src="./HeaderModule.scss" scoped></style>
