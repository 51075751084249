var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", [
    _c(
      "div",
      {
        directives: [
          {
            name: "show",
            rawName: "v-show",
            value: _vm.posts.lenght == 0,
            expression: "posts.lenght == 0"
          }
        ]
      },
      [
        _c("img", {
          attrs: { src: require("../../../assets/img/audienceGroth.png") }
        })
      ]
    ),
    _c(
      "div",
      [
        _c("card-audience-growth", {
          attrs: {
            posts: _vm.posts,
            image: false,
            "account-name": false,
            interactions: true,
            text: true,
            dates: _vm.dates,
            network: "tw"
          }
        })
      ],
      1
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }