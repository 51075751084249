<template>
  <div>
    <cp-table-stats
      :body-posts="bodyPosts"
      network="fb"
      :country="country"
      :dates="dates"
    />
  </div>
</template>

<script>
import CpTableStats from "../components/tableStat/CpTableStats.vue";
export default {
  name: "TableStats",
  props: {
    bodyPosts: {
      type: Array,
      default: function() {
        return [];
      }
    },
    country: {
      type: String,
      default: ""
    },
    dates: {
      require: true
    }
  },
  components: {
    CpTableStats
  }
};
</script>
