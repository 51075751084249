<template>
  <div>
    <div class="__container image-container-off" v-show="bodyPosts.length == 0">
      <div class="__container-title">Top 5 Fanpages States</div>
      <img src="../../../../assets/img/table.png" class="img-default" />
      <div class="image-container-off__message">
        <div>{{ titleImgOff }}</div>
        {{ msgImgOff }}
      </div>
    </div>
    <div class="__container" v-show="bodyPosts.length != 0">
      <div class="__container-title">
        <!-- {{ networkTitle }}  -->
        Top 5 Fanpages States in
        <span class="__container-title-country"> {{ country }} </span>
      </div>
      <div class="__container-pages w-100 d-flex">
        <div class="d-flex">
          <div>About Page</div>
          <div>About Posting</div>
        </div>
      </div>
      <div class="d-flex __container-table">
        <table class="__container-table-style">
          <thead>
            <tr>
              <th
                v-for="(header, key) in headers"
                :key="key"
                :style="
                  ([header == 'Name' && { 'text-align': 'left' }],
                  [header == 'Page Followers' && { width: widthPageFllr }])
                "
              >
                {{ header }}
                <span v-if="header === 'Reactions'">
                  <i class="fa fa-info-circle" id="reaction-1"></i>
                  <cp-tooltip-white-custom
                    target="reaction-1"
                    message="Total Reactions"
                    paddingContainer="30px 30px"
                  />
                </span>
              </th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="(page, key) in bodyPosts" :key="key">
              <td>
                <b-img
                  fluid
                  :src="
                    page.authorFollowers?.image ||
                      photoId(page.authorFollowers?.page_id, network) ||
                      accountImage
                  "
                  width="30"
                  height="30"
                  @error="
                    errorUrlImage(
                      $event,
                      network,
                      page.authorFollowers?.page_id
                    )
                  "
                />
                <a :href="page.linkPerfil" target="_blank">{{
                  page.authorFollowers?.page_name ?? "No Found"
                }}</a>
              </td>
              <td>
                <div v-show="country == 'peru'">
                  <img
                    src="../../../../assets/img/peru.png"
                    width="5"
                    height="20"
                    style="min-width: 25px"
                  />
                </div>
                <div v-show="country == 'chile'">
                  <img
                    src="../../../../assets/img/chile.png"
                    width="5"
                    height="18"
                    style="min-width: 25px"
                  />
                </div>
              </td>
              <td>
                {{
                  addCharacterPageFllr(
                    page.authorFollowers?.followers_count_end ?? 0,
                    page.authorFollowers?.followers_count_start ?? 0
                  )
                }}
              </td>
              <td
                v-if="isDateRange()"
                :style="[{ 'border-right': borderRgtNewFllr }]"
              >
                {{
                  (page.authorFollowers?.followers_count_end ?? 0) -
                    (page.authorFollowers?.followers_count_start ?? 0) >=
                  0
                    ? addCharacterNewFllr(
                        page.authorFollowers?.followers_count_end ?? 0,
                        page.authorFollowers?.followers_count_start ?? 0
                      )
                    : 0
                }}
              </td>
              <td :style="[{ 'border-left': borderLeftPosts }]">
                {{ addCharacterToValue(page.doc_count) }}
              </td>
              <td>
                {{ addCharacterToValue(page.summ_reactions.value) }}
              </td>
              <td>
                {{ addCharacterToValue(page.summ_comments.value) }}
              </td>
              <td>
                {{ addCharacterToValue(page.summ_shares.value) }}
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</template>
<script>
import { Helpers, CpTooltipWhiteCustom, mapGetters } from "@isc/styleguide";
import { commonMixin } from "../../../../mixins/commonMixin";
export default {
  name: "CpTableStats",
  components: { CpTooltipWhiteCustom },
  mixins: [commonMixin],
  props: {
    bodyPosts: {
      type: Array,
      default: function() {
        return [];
      }
    },
    network: {
      type: String,
      default: ""
    },
    country: {
      type: String,
      default: ""
    },
    dates: {
      require: true
    }
  },
  data() {
    return {
      headers: [],
      networkTitle: "",
      borderRgtNewFllr: "solid 1px #c5d0e4",
      borderLeftPosts: "unset",
      nameColNewFllr: "New Followers",
      widthPageFllr: "12%",
      accountImage: "../../../../assets/img/off-account_150x150.png"
    };
  },
  computed: {
    ...mapGetters({
      changeDateRangeHeader: "marketTrends/global/changeDateRangeHeader",
      msgImgOff: "global/helper/getImageOff",
      titleImgOff: "global/helper/getTitleImgOff"
    })
  },
  watch: {
    dates(value) {
      let isChange = value.rangeDays == 1;
      this.changeHeader(isChange);
    }
  },
  methods: {
    validateNetwork() {
      switch (this.network) {
        case "fb":
          this.headers = [
            "Name",
            "Country",
            "Page Followers",
            "New Followers",
            "Posts",
            "Reactions",
            "Comments",
            "Shares"
          ];
          this.networkTitle = "Facebook";
          break;
        case "tw":
          this.headers = [
            "Name",
            "Country",
            "Followers",
            "New Followers",
            "Tweets",
            "Favorites",
            "Comments",
            "Retweets"
          ];
          this.networkTitle = "Twitter";
          break;
        case "yt":
          this.headers = [
            "Name",
            "Country",
            "Subs",
            "New Subs",
            "Videos",
            "Likes",
            "Comments",
            "Views"
          ];
          this.networkTitle = "YouTube";
          break;
        default:
          break;
      }
    },
    isDateRange() {
      return this.dates.rangeDays != 1;
    },
    changeHeader(change) {
      if (this.network == "fb") {
        if (change) {
          this.headers = this.headers.filter(
            header => header != this.nameColNewFllr
          );
          this.borderLeftPosts = "solid 1px #c5d0e4";
          this.widthPageFllr = "24%";
        } else {
          if (!this.headers.includes(this.nameColNewFllr)) {
            this.headers.splice(3, 0, this.nameColNewFllr);
            this.borderRgtNewFllr = "solid 1px #c5d0e4";
            this.borderLeftPosts = "unset";
            this.widthPageFllr = "12%";
          }
        }
      }
    },
    addCharacterToValue(value) {
      if (value != null || value != undefined)
        return Helpers.addCharacter(value);
      else return 0;
    },
    addCharacterNewFllr(end, start) {
      if (!this.isDateRange()) {
        return Helpers.addCharacter(end);
      }
      return Helpers.addCharacter(Math.abs(end - start));
    },
    addCharacterPageFllr(end, start) {
      if (!this.isDateRange()) {
        return this.addCharacterNewFllr(end, start);
      }
      return this.addCharacterToValue(start);
    }
  },
  mounted() {
    this.validateNetwork();
    this.changeHeader(this.changeDateRangeHeader);
  }
};
</script>
<style lang="scss" src="./CpTableStats.scss" scoped />
