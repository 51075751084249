export const commonMixin = {
  methods: {
    photoId(idPage, networkId) {
      if (!idPage) return;
      let urlPhoto = null;
      if (networkId === "fb")
        urlPhoto = `${process.env.VUE_APP_GRAPH_FB}/${idPage}/picture`;
      return urlPhoto;
    },
    errorUrlImage(event, networkId, idPage) {
      if (networkId === "fb" && idPage) {
        event.target.src = `${process.env.VUE_APP_GRAPH_FB}/${idPage}/picture`;
      } else {
        event.target.src = require("../assets/img/off-account_150x150.png");
      }
    }
  }
};
