<template>
  <div>
    <loader-custom v-if="visibilityLoader === true"></loader-custom>
    <header-module title="Market Trends" @changeDate="getDate" />
    <div class="__tabs">
      <nav class="__tabs-nav">
        <a
          @click="currentTab = 'facebook'"
          class="__tabs-nav-a"
          :class="currentTab === 'facebook' && '.__tabs-nav-a-start-facebook'"
          >Facebook <i class="fab fa-facebook"></i
        ></a>
        <a
          @click="currentTab = 'twitter'"
          class="__tabs-nav-a"
          :class="currentTab === 'twitter' && '.__tabs-nav-a-start-twitter'"
          >Twitter <i class="fab fa-twitter"></i
        ></a>
        <div :class="getCurrentTab"></div>
      </nav>
      <div class="__tabs-view">
        <div v-if="currentTab === 'facebook'" class="__tabs-view-container">
          <facebook-module
            :dates="dates"
            :geoDrivers="dataGeoDriversFacebook"
          />
        </div>
        <div v-if="currentTab === 'twitter'" style="background-color: white">
          <twitter-module :dates="dates" :geoDrivers="dataGeoDriversTwitter" />
        </div>
      </div>
    </div>
    <div>
      <IscModalAlerts
        v-model="showAlert"
        :icon="icon"
        :iconColor="iconColor"
        :title="title"
        :paragraph="paragraph"
        :buttonMessage="buttonMessage"
        @sendResult="resultModalAlerts"
      >
      </IscModalAlerts>
    </div>
  </div>
</template>

<script>
import HeaderModule from "../../components/modules/header/HeaderModule.vue";
import FacebookModule from "../../components/modules/facebook/FacebookModule.vue";
import TwitterModule from "../../components/modules/twitter/TwitterModule.vue";
import { validatePostGeodrivers } from "../../services/socialNetwork.service";
import {
  loaderCustom,
  IscModalAlerts,
  mapActions,
  mapGetters
} from "@isc/styleguide";
//import { responseGeodrivers } from "../../../example_geodrivers.js";

export default {
  name: "Industry",
  components: {
    HeaderModule,
    FacebookModule,
    loaderCustom,
    TwitterModule,
    IscModalAlerts
  },
  data() {
    return {
      currentTab: "facebook",
      dates: null,
      dataGeoDriversFacebook: null,
      dataGeoDriversTwitter: null,
      visibilityLoader: true,
      showAlert: false,
      icon: "fa fa-times-circle",
      iconColor: "#FF0000",
      title: "Error",
      paragraph: "",
      buttonMessage: "Ok"
    };
  },
  computed: {
    ...mapGetters({
      getDataGeoDrivers: "marketTrends/global/respGeoDrivers"
    }),
    getCurrentTab() {
      return `animation __tabs-nav-a-start-${this.currentTab}`;
    }
  },
  watch: {
    currentTab(value) {
      this.setGeoDrivers({
        dataFacebook: this.dataGeoDriversFacebook,
        dataTwitter: this.dataGeoDriversTwitter,
        socialNetwork: value
      });
    }
  },
  methods: {
    ...mapActions({
      setGeoDrivers: "marketTrends/global/SET_DATA_DRIVERS",
      resetGeoDrivers: "marketTrends/global/RESET_DATA_DRIVERS",
      setChangeDateRangeHeader:
        "marketTrends/global/SET_CHANGE_DATE_RANGE_HEADER"
    }),
    getDate(date) {
      this.dates = date;
      let isChange = date.rangeDays == 1;
      this.setChangeDateRangeHeader(isChange);
    },
    resultModalAlerts(value) {
      this.showAlert = value;
    }
  },

  async mounted() {
    let dataGeoDriversFacebook = await validatePostGeodrivers("facebook");
    let dataGeoDriversTwitter = await validatePostGeodrivers("twitter");
    //console.log('geo drivers Twitter ', dataGeoDriversTwitter);
    //console.log('geo drivers Facebook ', dataGeoDriversFacebook);

    //dataGeoDriversTwitter.data.geoDrivers.peru.categories.sony = []
    // this is only for default data
    //let dataGeoDriversFacebook = responseGeodrivers;
    if (!dataGeoDriversFacebook || !dataGeoDriversTwitter) {
      this.showAlert = true;
      this.paragraph = "Fail to retrieve Geo Drivers Facebook or Twitter";
    }

    this.visibilityLoader = false;
    this.dataGeoDriversFacebook = Object.assign({}, dataGeoDriversFacebook);
    this.dataGeoDriversTwitter = Object.assign({}, dataGeoDriversTwitter);
    this.setGeoDrivers({
      dataFacebook: dataGeoDriversFacebook,
      dataTwitter: dataGeoDriversTwitter,
      socialNetwork: "facebook"
    });
  },
  destroyed() {
    this.resetGeoDrivers();
  }
};
</script>

<style lang="scss" src="./Industry.scss" scoped></style>
