var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", [
    _c(
      "div",
      { staticClass: "container-select" },
      [
        _c("selected-pannels", {
          attrs: {
            title: _vm.counTitle,
            image: _vm.counImage,
            selected: _vm.counSelected,
            options: _vm.optionsCountry,
            disabled: false
          },
          on: { selectValue: _vm.selectValue1 }
        }),
        _c("selected-pannels", {
          attrs: {
            title: _vm.indTitle,
            image: _vm.indImage,
            selected: _vm.indSelected,
            options: _vm.filterInd,
            disabled: _vm.disabled
          },
          on: { selectValue: _vm.selectValue2 }
        })
      ],
      1
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }