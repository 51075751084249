var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _vm.visibilityLoader === true ? _c("loader-custom") : _vm._e(),
      _c("header-module", {
        attrs: { title: "Market Trends" },
        on: { changeDate: _vm.getDate }
      }),
      _c("div", { staticClass: "__tabs" }, [
        _c("nav", { staticClass: "__tabs-nav" }, [
          _c(
            "a",
            {
              staticClass: "__tabs-nav-a",
              class:
                _vm.currentTab === "facebook" && ".__tabs-nav-a-start-facebook",
              on: {
                click: function($event) {
                  _vm.currentTab = "facebook"
                }
              }
            },
            [_vm._v("Facebook "), _c("i", { staticClass: "fab fa-facebook" })]
          ),
          _c(
            "a",
            {
              staticClass: "__tabs-nav-a",
              class:
                _vm.currentTab === "twitter" && ".__tabs-nav-a-start-twitter",
              on: {
                click: function($event) {
                  _vm.currentTab = "twitter"
                }
              }
            },
            [_vm._v("Twitter "), _c("i", { staticClass: "fab fa-twitter" })]
          ),
          _c("div", { class: _vm.getCurrentTab })
        ]),
        _c("div", { staticClass: "__tabs-view" }, [
          _vm.currentTab === "facebook"
            ? _c(
                "div",
                { staticClass: "__tabs-view-container" },
                [
                  _c("facebook-module", {
                    attrs: {
                      dates: _vm.dates,
                      geoDrivers: _vm.dataGeoDriversFacebook
                    }
                  })
                ],
                1
              )
            : _vm._e(),
          _vm.currentTab === "twitter"
            ? _c(
                "div",
                { staticStyle: { "background-color": "white" } },
                [
                  _c("twitter-module", {
                    attrs: {
                      dates: _vm.dates,
                      geoDrivers: _vm.dataGeoDriversTwitter
                    }
                  })
                ],
                1
              )
            : _vm._e()
        ])
      ]),
      _c(
        "div",
        [
          _c("IscModalAlerts", {
            attrs: {
              icon: _vm.icon,
              iconColor: _vm.iconColor,
              title: _vm.title,
              paragraph: _vm.paragraph,
              buttonMessage: _vm.buttonMessage
            },
            on: { sendResult: _vm.resultModalAlerts },
            model: {
              value: _vm.showAlert,
              callback: function($$v) {
                _vm.showAlert = $$v
              },
              expression: "showAlert"
            }
          })
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }