var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", [
    _c(
      "div",
      { staticClass: "container-title-top-content" },
      [
        _c("span", [_vm._v("Top 3 Hashtags")]),
        _c("i", {
          staticClass: "fa fa-info-circle",
          attrs: { id: "top-hashtags-title" }
        }),
        _c("CpTooltipWhiteCustom", {
          attrs: {
            target: "top-hashtags-title",
            title: "top-hashtags-title",
            paddingContainer: "10px 1px 10px 1px",
            message:
              "Due to insufficient data from the industry, it will not be possible to show the complete top 3 hashtags."
          }
        })
      ],
      1
    ),
    _c(
      "div",
      { staticClass: "container-card-top-hashtags" },
      [_c("card-top-hashtags", { attrs: { posts: _vm.posts } })],
      1
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }