var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", [
    _c("div", [
      _c("a", { staticClass: "market" }, [_vm._v(_vm._s(_vm.title))]),
      _c(
        "div",
        { staticClass: "market_combo" },
        [
          _c("img", {
            staticClass: "market_combo-image",
            attrs: { src: _vm.image }
          }),
          _c("selects", {
            staticClass: "market_combo-selects d-flex w-100",
            attrs: {
              selected: _vm.selected,
              options: _vm.options,
              disabled: _vm.disabled
            },
            on: { selectValue: _vm.selectValue }
          })
        ],
        1
      )
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }