<template>
  <div>
    <div class="container-title-top-content">
      <span>Top 3 Audience Growth</span>
      <i class="fa fa-info-circle"></i>
    </div>
    <div>
      <card-audience-growth
        :posts="posts"
        :image="true"
        :account-name="true"
        :interactions="true"
        :text="true"
        :dates="dates"
        network="fb"
      />
    </div>
  </div>
</template>

<script>
import CardAudienceGrowth from "../../../components/cards/cardAudienceGrowth/CardAudienceGrowth.vue";
export default {
  name: "AudienceGrowth",
  components: {
    CardAudienceGrowth
  },
  props: {
    posts: {
      type: Array,
      default: function() {
        return [];
      }
    },
    dates: {
      require: true
    }
  }
};
</script>

<style lang="scss" scoped>
.container-title-top-content {
  font-family: Oxygen;
  font-size: 15px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.33;
  letter-spacing: normal;
  color: #2c3852;
  padding-left: 50px;
  margin-left: 1em;
  padding-top: 2em;
  font-weight: bold;

  span {
    padding-right: 0.5rem;
  }

  i {
    color: #a2acc4;
    font-size: 13px;
  }
}
</style>
