<template>
  <div>
    <div v-show="posts.length == 0" class="container image-container-off">
      <img
        src="../../../assets/img/hashtag.png"
        class="img-default img-default-hashtag"
      />
      <div class="image-container-off__message">
        <div>{{ titleImgOff }}</div>
        {{ msgImgOff }}
      </div>
    </div>
    <div v-show="posts.length != 0" class="container">
      <div v-for="(post, key) in posts" :key="key" class="container_post">
        <div class="container_post_hashtag">
          <div class="container_post_hashtag-container">
            <div class="container_post_hashtag-container_id">{{ key + 1 }}</div>
          </div>
          <div class="container_post_hashtag-container2">
            <div class="container_post_hashtag-container2_title">
              #{{ post.key }}
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { mapGetters } from "@isc/styleguide";

export default {
  name: "CardTopHashtags",
  props: {
    posts: {
      type: Array,
      default: function() {
        return [];
      }
    }
  },
  computed: {
    ...mapGetters({
      msgImgOff: "global/helper/getImageOff",
      titleImgOff: "global/helper/getTitleImgOff"
    })
  },
  methods: {}
};
</script>
<style lang="scss" src="./CardTopHashtags.scss" scoped />
