var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", [
    _vm._m(0),
    _c(
      "div",
      [
        _c("card-audience-growth", {
          attrs: {
            posts: _vm.posts,
            image: true,
            "account-name": true,
            interactions: true,
            text: true,
            dates: _vm.dates,
            network: "fb"
          }
        })
      ],
      1
    )
  ])
}
var staticRenderFns = [
  function() {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "container-title-top-content" }, [
      _c("span", [_vm._v("Top 3 Audience Growth")]),
      _c("i", { staticClass: "fa fa-info-circle" })
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }