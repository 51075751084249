<template>
  <div>
    <div class="container-title-top-content">
      Top 3 Facebook Posts
    </div>
    <div v-show="posts.lenght == 0">
      <img src="../../../assets/img/off-top-post.png" />
    </div>
    <div>
      <card-top-post
        :posts="posts"
        :shared="true"
        :likes="false"
        :unlikes="false"
        :comments="true"
        :views="false"
        :favorites="true"
        :retweet="false"
        network="fb"
      />
    </div>
  </div>
</template>

<script>
import CardTopPost from "../../../components/cards/cardTopPost/CardTopPost.vue";
export default {
  name: "TopPosts",
  components: {
    CardTopPost
  },
  props: {
    posts: {
      type: Array,
      default: function() {
        return [];
      }
    }
  },
  data() {
    return {};
  }
};
</script>

<style lang="scss" scoped>
.container-title-top-content {
  font-family: Oxygen;
  font-size: 15px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.33;
  letter-spacing: normal;
  color: #2c3852;
  padding-left: 50px;
  margin-left: 1em;
  padding-top: 6rem;
  font-weight: bold;
}
</style>
