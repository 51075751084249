<template>
  <div>
    <!-- <loader-custom v-if="visibilityLoader === true"></loader-custom> -->
    <div class="container-select">
      <selected-pannels
        :title="counTitle"
        :image="counImage"
        :selected="counSelected"
        :options="optionsCountry"
        @selectValue="selectValue1"
        :disabled="false"
      />
      <selected-pannels
        :title="indTitle"
        :image="indImage"
        :selected="indSelected"
        :options="filterInd"
        @selectValue="selectValue2"
        :disabled="disabled"
      />
    </div>
  </div>
</template>
<script>
/* import { loaderCustom } from "@isc/styleguide"; */
import SelectedPannels from ".././../../panels/selectedPanel/SelectedPannels.vue";
import imgCountries from "../../../../assets/img/countries.png";
import imgIndustries from "../../../../assets/img/industries.png";
import { mapGetters, mapActions } from "@isc/styleguide";

export default {
  name: "selectedPanelGlobal",
  components: {
    //loaderCustom,
    SelectedPannels
  },
  props: {
    dates: {
      require: true
    },
    geoDrivers: {
      type: Object,
      default: null
    }
  },
  data() {
    return {
      visibilityLoader: true,
      counTitle: "Selected Countries on Facebook",
      indTitle: "Selected Industries on Facebook",
      counImage: imgCountries,
      indImage: imgIndustries,
      counSelected: {
        text: "1. Select a country",
        value: "Select a country",
        disabled: true
      },
      indSelected: {
        text: "2. Select an industry",
        value: "Select an industry",
        disabled: true
      },
      optionsCountry: [],
      optionsInd: [],
      filterInd: [],
      disabled: true,
      selectCountry: "",
      selectInd: "",
      dataTopPost: [],
      dataAudience: [],
      dataTable: []
    };
  },
  watch: {
    async dates(value) {
      value.date[0] = new Date(value.date[0]).setHours(0, 0, 59);
      value.date[1] = new Date(value.date[1]).setHours(23, 59, 59);
      if ((this.selectCountry != "", this.selectInd != "")) {
        this.sendData();
      }
    },
    geoDrivers(dataResp) {
      if (Object.keys(dataResp).length !== 0) {
        const { success, data, error } = dataResp;
        if (success) {
          this.orderGeoDriver(data);
        } else {
          console.log("Error: ", error);
        }
      }
    }
  },
  computed: {
    ...mapGetters({
      getDataGeoDrivers: "marketTrends/global/respGeoDrivers"
    })
  },
  methods: {
    ...mapActions({
      setGeoDrivers: "marketTrends/global/SET_DATA_DRIVERS"
    }),
    addSelectValues() {
      if (!this.getDataGeoDrivers) return;
      const { success, data, error } = this.getDataGeoDrivers;
      if (!success) {
        console.log("Error: ", error);
      }
      this.orderGeoDriver(data);
    },
    async selectValue1(value) {
      if (this.disabled) this.disabled = value.disabled;
      this.selectCountry = value.value;
      this.filterInd = this.optionsInd.filter(
        f => f.country == this.selectCountry || f.value == this.indSelected
      );
      if ((this.dates.date.length != 0, this.selectInd != "")) this.sendData();
    },
    async selectValue2(value) {
      this.selectInd = value.value;
      this.sendData();
    },
    sendData() {
      let body = {
        country: this.selectCountry,
        industry: this.selectInd,
        start_date: this.dates.date[0],
        end_date: this.dates.date[1]
      };
      this.$emit("sendData", body);
    },
    orderGeoDriver(data) {
      const gDrivers = data.geoDrivers;
      Object.keys(gDrivers).map(g => {
        this.optionsCountry.push({ text: g, value: g, disabled: false });
        Object.keys(gDrivers[g].categories).map(value => {
          this.optionsInd.push({
            text: value,
            value: value,
            disabled: false,
            country: g
          });
        });
      });
    }
  },
  mounted() {
    /* when the user selects different social network, function addSelectValues it will trigger again, therefore the component will mount again too.
     */
    this.addSelectValues();
  }
};
</script>
<style scoped>
.container-select {
  display: flex;
  padding-left: 65px;
  padding-right: 65px;
  justify-content: space-between;
}
</style>
