<template>
  <div>
    <div>
      <a class="market">{{ title }}</a>
      <div class="market_combo">
        <img :src="image" class="market_combo-image" />
        <selects
          :selected="selected"
          :options="options"
          :disabled="disabled"
          @selectValue="selectValue"
          class="market_combo-selects d-flex w-100"
        />
      </div>
    </div>
  </div>
</template>
<script>
import Selects from "../../selects/Selects.vue";
export default {
  name: "SelectedPannels",
  props: {
    title: {
      type: String,
      default: ""
    },
    image: {
      type: String,
      default: ""
    },
    selected: {
      type: Object
    },
    options: {
      type: Array,
      default: function() {
        return [];
      }
    },
    disabled: {
      type: Boolean,
      default: false
    }
  },
  components: {
    Selects
  },
  data() {
    return {};
  },
  methods: {
    selectValue(value) {
      this.$emit("selectValue", value);
    }
  },
  mounted() {}
};
</script>
<style lang="scss" src="./SelectedPannels.scss" scoped></style>
