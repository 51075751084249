var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", [
    _vm.posts.length == 0
      ? _c("div", { staticClass: "container image-container-off-audience" }, [
          _vm.network === "fb"
            ? _c("div", { staticClass: "w-100" }, [
                _c("img", {
                  staticClass: "img-default img-default-audience",
                  attrs: {
                    src: require("../../../assets/img/audienceGroth.png")
                  }
                })
              ])
            : _vm.network === "tw"
            ? _c("div", { staticClass: "w-100" }, [
                _c("img", {
                  staticClass: "img-default img-default-audience",
                  attrs: {
                    src: require("../../../assets/img/audience-default-twitter.png")
                  }
                })
              ])
            : _vm._e(),
          _c("div", { staticClass: "image-container-off-audience__message" }, [
            _c("div", [_vm._v(_vm._s(_vm.titleImgOff))]),
            _vm._v(" " + _vm._s(_vm.msgImgOff) + " ")
          ])
        ])
      : _c(
          "div",
          { staticClass: "container" },
          _vm._l(_vm.posts, function(post, key) {
            return _c("div", { key: key, staticClass: "container_post" }, [
              _c(
                "div",
                {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: _vm.image,
                      expression: "image"
                    }
                  ],
                  staticClass: "container_post-profile"
                },
                [
                  _c(
                    "a",
                    { attrs: { target: "_blank" } },
                    [
                      _c("b-img", {
                        staticClass: "container_post-profile_image",
                        attrs: {
                          fluid: "",
                          src:
                            post.image ||
                            _vm.photoId(post.page_id, _vm.network) ||
                            _vm.imageAccount
                        },
                        on: {
                          error: function($event) {
                            return _vm.errorUrlImage(
                              $event,
                              _vm.network,
                              post.page_id
                            )
                          }
                        }
                      })
                    ],
                    1
                  )
                ]
              ),
              _c(
                "div",
                {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: _vm.accountName,
                      expression: "accountName"
                    }
                  ],
                  staticClass: "container_post-name"
                },
                [_vm._v(" " + _vm._s(post.page_name) + " ")]
              ),
              _c(
                "div",
                {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: _vm.interactions,
                      expression: "interactions"
                    }
                  ],
                  staticClass: "container_post-interactions"
                },
                [
                  _c("div", { staticClass: "d-flex" }, [
                    _c("span", [
                      _vm._v(
                        " " +
                          _vm._s(
                            post.followers_count_end != null
                              ? _vm.getInteractions(
                                  post.followers_count_end,
                                  post.followers_count_start
                                )
                              : _vm.addCharacter(post.doc_count)
                          ) +
                          " "
                      )
                    ]),
                    _c(
                      "div",
                      {
                        directives: [
                          {
                            name: "show",
                            rawName: "v-show",
                            value: _vm.isDateRange() && _vm.network == "fb",
                            expression: "isDateRange() && network == 'fb'"
                          }
                        ]
                      },
                      [
                        post.followers_count_end > post.followers_count_start
                          ? _c("i", { staticClass: "pup fa fa-sort-up" })
                          : _vm._e(),
                        post.followers_count_end < post.followers_count_start
                          ? _c("i", { staticClass: "pdw fa fa-sort-down" })
                          : _vm._e()
                      ]
                    )
                  ])
                ]
              ),
              _c(
                "div",
                {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: _vm.text,
                      expression: "text"
                    }
                  ],
                  staticClass: "container_post-text"
                },
                [
                  _vm._v(
                    " " +
                      _vm._s(
                        _vm.getMessage(
                          Math.sign(
                            post.followers_count_end -
                              post.followers_count_start
                          )
                        )
                      ) +
                      " "
                  )
                ]
              )
            ])
          }),
          0
        )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }