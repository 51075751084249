<template>
  <div>
    <div
      v-if="posts.length == 0"
      class="container image-container-off-audience"
    >
      <div v-if="network === 'fb'" class="w-100">
        <img
          src="../../../assets/img/audienceGroth.png"
          class="img-default img-default-audience"
        />
      </div>
      <div v-else-if="network === 'tw'" class="w-100">
        <img
          src="../../../assets/img/audience-default-twitter.png"
          class="img-default img-default-audience"
        />
      </div>
      <div class="image-container-off-audience__message">
        <div>{{ titleImgOff }}</div>
        {{ msgImgOff }}
      </div>
    </div>
    <div v-else class="container">
      <div v-for="(post, key) in posts" :key="key" class="container_post">
        <div v-show="image" class="container_post-profile">
          <!-- <a :href="post.linkPerfil" target="_blank">
            <img :src="post.image" class="container_post-profile_image" />
          </a> -->
          <a target="_blank">
            <b-img
              fluid
              :src="
                post.image || photoId(post.page_id, network) || imageAccount
              "
              class="container_post-profile_image"
              @error="errorUrlImage($event, network, post.page_id)"
            />
          </a>
        </div>
        <div v-show="accountName" class="container_post-name">
          {{ post.page_name }}
        </div>
        <div v-show="interactions" class="container_post-interactions">
          <div class="d-flex">
            <span>
              {{
                post.followers_count_end != null
                  ? getInteractions(
                      post.followers_count_end,
                      post.followers_count_start
                    )
                  : addCharacter(post.doc_count)
              }}
            </span>
            <div v-show="isDateRange() && network == 'fb'">
              <i
                v-if="post.followers_count_end > post.followers_count_start"
                class="pup fa fa-sort-up"
              ></i>
              <i
                v-if="post.followers_count_end < post.followers_count_start"
                class="pdw fa fa-sort-down"
              ></i>
            </div>
          </div>
        </div>
        <div v-show="text" class="container_post-text">
          {{
            getMessage(
              Math.sign(post.followers_count_end - post.followers_count_start)
            )
          }}
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { Helpers, mapGetters } from "@isc/styleguide";
import { commonMixin } from "../../../mixins/commonMixin";
export default {
  name: "CardAudienceGrowth",
  mixins: [commonMixin],
  data() {
    return {
      imageAccount: "../../../assets/img/off-account_150x150.png"
    };
  },
  props: {
    posts: {
      type: Array,
      default: function() {
        return [];
      }
    },
    image: {
      type: Boolean,
      default: false
    },
    accountName: {
      type: Boolean,
      default: false
    },
    interactions: {
      type: Boolean,
      default: false
    },
    text: {
      type: Boolean,
      default: false
    },
    dates: {
      require: true
    },
    network: {
      type: String,
      default: ""
    }
  },
  computed: {
    ...mapGetters({
      msgImgOff: "global/helper/getImageOff",
      titleImgOff: "global/helper/getTitleImgOff"
    })
  },
  methods: {
    isDateRange() {
      return this.dates.rangeDays != 1;
    },
    getInteractions(end, start) {
      if (this.dates.rangeDays == 1) {
        return Helpers.addCharacter(end);
      }
      return Helpers.addCharacter(Math.abs(end - start));
    },
    getMessage(value) {
      switch (this.network) {
        case "fb":
          if (this.dates.rangeDays == 1) {
            return "Followers";
          } else {
            if (value == 1 || value == 0) {
              return "New Followers";
            }
            if (value == -1) {
              return "Followers";
            }
          }
          break;
        case "tw":
          return "Mentions";
        default:
          return "";
      }
    },
    addCharacter(value) {
      return Helpers.addCharacter(value);
    }
  }
};
</script>
<style lang="scss" src="./CardAudienceGrowth.scss" scoped />
