<template>
  <div>
    <SelectCustom
      :selected="selected"
      :options="options"
      :disabled="disabled"
      @selectValue="selectValue"
    >
    </SelectCustom>
  </div>
</template>
<script>
import { SelectCustom } from "@isc/styleguide";
export default {
  name: "Selects",
  components: {
    SelectCustom
  },
  data() {
    return {};
  },
  props: {
    selected: {
      type: Object
    },
    options: {
      type: Array,
      default: function() {
        return [];
      }
    },
    disabled: {
      type: Boolean,
      default: false
    }
  },
  methods: {
    selectValue(vSelected) {
      this.$emit("selectValue", { disabled: false, value: vSelected.value });
    }
  }
};
</script>
