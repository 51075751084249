<template>
  <div>
    <loader-custom v-if="visibilityLoader === true"></loader-custom>
    <selected-panel-global
      :dates="dates"
      @sendData="sendData"
      :geoDrivers="geoDrivers"
    ></selected-panel-global>
    <div>
      <top-posts-twitter :posts="dataTopPost"></top-posts-twitter>
      <top-hashtags :posts="dataHashtags"></top-hashtags>
      <audience-growth-twitter
        :posts="dataAudience"
        :dates="dates"
      ></audience-growth-twitter>
    </div>
    <div>
      <IscModalAlerts
        v-model="showAlert"
        :icon="icon"
        :iconColor="iconColor"
        :title="title"
        :paragraph="paragraph"
        :buttonMessage="buttonMessage"
        @sendResult="resultModalAlerts"
      >
      </IscModalAlerts>
    </div>
  </div>
</template>

<script>
import { loaderCustom, IscModalAlerts } from "@isc/styleguide";
import SelectedPanelGlobal from "../../content/components/selectedPanelGlobal/selectedPanelGlobal.vue";
import TopPostsTwitter from "../../content/twitter/TopPostsTwitter.vue";
import TopHashtags from "../../content/twitter/TopHashtags.vue";
import AudienceGrowthTwitter from "../../content/twitter/AudienceGrowthTwitter.vue";
import { validatePostInsights } from "../../../services/socialNetwork.service";
export default {
  name: "TwitterModule",
  components: {
    SelectedPanelGlobal,
    TopPostsTwitter,
    TopHashtags,
    AudienceGrowthTwitter,
    loaderCustom,
    IscModalAlerts
  },
  props: {
    dates: {
      require: true
    },
    geoDrivers: {
      type: Object,
      default: null
    }
  },
  data() {
    return {
      visibilityLoader: false,
      dataTopPost: [],
      dataHashtags: [],
      dataAudience: [],
      showAlert: false,
      icon: "fa fa-times-circle",
      iconColor: "#FF0000",
      title: "Error",
      paragraph: "",
      buttonMessage: "Ok"
    };
  },
  methods: {
    async sendData(body) {
      await this.getData(body);
    },
    async getData(body) {
      this.visibilityLoader = true;
      const response = await validatePostInsights(body, "twitter");
      if (response === null) {
        console.log("ERROR");
        this.visibilityLoader = false;
        this.showAlert = true;
        this.paragraph = "Please wait a few minutes before you try again.";
      } else {
        this.dataTopPost = [];
        this.dataHashtags = [];
        this.dataAudience = [];
        const { data } = response;
        this.sendDataToComponents(data);
      }
    },
    sendDataToComponents(data) {
      this.dataTopPost = data.top_3_tweets_interactions.hits.hits;
      this.dataHashtags = data.top_3_hashtags.buckets;
      this.dataAudience = data.top_3_hashtags.buckets;
      this.visibilityLoader = false;
    },
    resultModalAlerts(value) {
      this.showAlert = value;
    }
  }
};
</script>

<style lang="sass" scoped></style>
