var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", [
    _c(
      "div",
      {
        directives: [
          {
            name: "show",
            rawName: "v-show",
            value: _vm.bodyPosts.length == 0,
            expression: "bodyPosts.length == 0"
          }
        ],
        staticClass: "__container image-container-off"
      },
      [
        _c("div", { staticClass: "__container-title" }, [
          _vm._v("Top 5 Fanpages States")
        ]),
        _c("img", {
          staticClass: "img-default",
          attrs: { src: require("../../../../assets/img/table.png") }
        }),
        _c("div", { staticClass: "image-container-off__message" }, [
          _c("div", [_vm._v(_vm._s(_vm.titleImgOff))]),
          _vm._v(" " + _vm._s(_vm.msgImgOff) + " ")
        ])
      ]
    ),
    _c(
      "div",
      {
        directives: [
          {
            name: "show",
            rawName: "v-show",
            value: _vm.bodyPosts.length != 0,
            expression: "bodyPosts.length != 0"
          }
        ],
        staticClass: "__container"
      },
      [
        _c("div", { staticClass: "__container-title" }, [
          _vm._v(" Top 5 Fanpages States in "),
          _c("span", { staticClass: "__container-title-country" }, [
            _vm._v(" " + _vm._s(_vm.country) + " ")
          ])
        ]),
        _vm._m(0),
        _c("div", { staticClass: "d-flex __container-table" }, [
          _c("table", { staticClass: "__container-table-style" }, [
            _c("thead", [
              _c(
                "tr",
                _vm._l(_vm.headers, function(header, key) {
                  return _c(
                    "th",
                    {
                      key: key,
                      style:
                        ([header == "Name" && { "text-align": "left" }],
                        [
                          header == "Page Followers" && {
                            width: _vm.widthPageFllr
                          }
                        ])
                    },
                    [
                      _vm._v(" " + _vm._s(header) + " "),
                      header === "Reactions"
                        ? _c(
                            "span",
                            [
                              _c("i", {
                                staticClass: "fa fa-info-circle",
                                attrs: { id: "reaction-1" }
                              }),
                              _c("cp-tooltip-white-custom", {
                                attrs: {
                                  target: "reaction-1",
                                  message: "Total Reactions",
                                  paddingContainer: "30px 30px"
                                }
                              })
                            ],
                            1
                          )
                        : _vm._e()
                    ]
                  )
                }),
                0
              )
            ]),
            _c(
              "tbody",
              _vm._l(_vm.bodyPosts, function(page, key) {
                return _c("tr", { key: key }, [
                  _c(
                    "td",
                    [
                      _c("b-img", {
                        attrs: {
                          fluid: "",
                          src:
                            page.authorFollowers?.image ||
                            _vm.photoId(
                              page.authorFollowers?.page_id,
                              _vm.network
                            ) ||
                            _vm.accountImage,
                          width: "30",
                          height: "30"
                        },
                        on: {
                          error: function($event) {
                            return _vm.errorUrlImage(
                              $event,
                              _vm.network,
                              page.authorFollowers?.page_id
                            )
                          }
                        }
                      }),
                      _c(
                        "a",
                        { attrs: { href: page.linkPerfil, target: "_blank" } },
                        [
                          _vm._v(
                            _vm._s(
                              page.authorFollowers?.page_name ?? "No Found"
                            )
                          )
                        ]
                      )
                    ],
                    1
                  ),
                  _c("td", [
                    _c(
                      "div",
                      {
                        directives: [
                          {
                            name: "show",
                            rawName: "v-show",
                            value: _vm.country == "peru",
                            expression: "country == 'peru'"
                          }
                        ]
                      },
                      [
                        _c("img", {
                          staticStyle: { "min-width": "25px" },
                          attrs: {
                            src: require("../../../../assets/img/peru.png"),
                            width: "5",
                            height: "20"
                          }
                        })
                      ]
                    ),
                    _c(
                      "div",
                      {
                        directives: [
                          {
                            name: "show",
                            rawName: "v-show",
                            value: _vm.country == "chile",
                            expression: "country == 'chile'"
                          }
                        ]
                      },
                      [
                        _c("img", {
                          staticStyle: { "min-width": "25px" },
                          attrs: {
                            src: require("../../../../assets/img/chile.png"),
                            width: "5",
                            height: "18"
                          }
                        })
                      ]
                    )
                  ]),
                  _c("td", [
                    _vm._v(
                      " " +
                        _vm._s(
                          _vm.addCharacterPageFllr(
                            page.authorFollowers?.followers_count_end ?? 0,
                            page.authorFollowers?.followers_count_start ?? 0
                          )
                        ) +
                        " "
                    )
                  ]),
                  _vm.isDateRange()
                    ? _c(
                        "td",
                        { style: [{ "border-right": _vm.borderRgtNewFllr }] },
                        [
                          _vm._v(
                            " " +
                              _vm._s(
                                (page.authorFollowers?.followers_count_end ??
                                  0) -
                                  (page.authorFollowers
                                    ?.followers_count_start ?? 0) >=
                                  0
                                  ? _vm.addCharacterNewFllr(
                                      page.authorFollowers
                                        ?.followers_count_end ?? 0,
                                      page.authorFollowers
                                        ?.followers_count_start ?? 0
                                    )
                                  : 0
                              ) +
                              " "
                          )
                        ]
                      )
                    : _vm._e(),
                  _c(
                    "td",
                    { style: [{ "border-left": _vm.borderLeftPosts }] },
                    [
                      _vm._v(
                        " " +
                          _vm._s(_vm.addCharacterToValue(page.doc_count)) +
                          " "
                      )
                    ]
                  ),
                  _c("td", [
                    _vm._v(
                      " " +
                        _vm._s(
                          _vm.addCharacterToValue(page.summ_reactions.value)
                        ) +
                        " "
                    )
                  ]),
                  _c("td", [
                    _vm._v(
                      " " +
                        _vm._s(
                          _vm.addCharacterToValue(page.summ_comments.value)
                        ) +
                        " "
                    )
                  ]),
                  _c("td", [
                    _vm._v(
                      " " +
                        _vm._s(
                          _vm.addCharacterToValue(page.summ_shares.value)
                        ) +
                        " "
                    )
                  ])
                ])
              }),
              0
            )
          ])
        ])
      ]
    )
  ])
}
var staticRenderFns = [
  function() {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "__container-pages w-100 d-flex" }, [
      _c("div", { staticClass: "d-flex" }, [
        _c("div", [_vm._v("About Page")]),
        _c("div", [_vm._v("About Posting")])
      ])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }