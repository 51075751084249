var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c("SelectCustom", {
        attrs: {
          selected: _vm.selected,
          options: _vm.options,
          disabled: _vm.disabled
        },
        on: { selectValue: _vm.selectValue }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }