<template>
  <div>
    <loader-custom v-if="visibilityLoader === true"></loader-custom>
    <div>
      <selected-panel-global
        :dates="dates"
        @sendData="sendData"
        :geoDrivers="geoDrivers"
      ></selected-panel-global>
      <div v-show="showResults">
        <top-posts :posts="dataTopPost" />
        <audience-growth :posts="dataAudience" :dates="dates" />
        <table-stats
          :body-posts="dataTable"
          :country="selectCountry"
          :dates="dates"
        ></table-stats>
      </div>
    </div>
    <div>
      <IscModalAlerts
        v-model="showAlert"
        :icon="icon"
        :iconColor="iconColor"
        :title="title"
        :paragraph="paragraph"
        :buttonMessage="buttonMessage"
        @sendResult="resultModalAlerts"
      >
      </IscModalAlerts>
    </div>
  </div>
</template>
<script>
import { loaderCustom, IscModalAlerts } from "@isc/styleguide";
import TopPosts from "../../content/facebook/TopPosts.vue";
import AudienceGrowth from "../../content/facebook/AudienceGrowth.vue";
import TableStats from "../../content/facebook/TableStats.vue";
import selectedPanelGlobal from "../../content/components/selectedPanelGlobal/selectedPanelGlobal.vue";
import { validatePostInsights } from "../../../services/socialNetwork.service";
export default {
  name: "FacebookModule",
  components: {
    TopPosts,
    AudienceGrowth,
    TableStats,
    selectedPanelGlobal,
    loaderCustom,
    IscModalAlerts
  },
  props: {
    dates: {
      require: true
    },
    geoDrivers: {
      type: Object,
      default: null
    }
  },
  data() {
    return {
      showResults: true,
      dataTopPost: [],
      dataAudience: [],
      dataTable: [],
      selectCountry: "",
      visibilityLoader: false,
      showAlert: false,
      icon: "fa fa-times-circle",
      iconColor: "#FF0000",
      title: "Error",
      paragraph: "",
      buttonMessage: "Ok"
    };
  },

  methods: {
    async sendData(body) {
      this.selectCountry = body.country;
      await this.getData(body);
    },
    async getData(body) {
      this.visibilityLoader = true;
      const response = await validatePostInsights(body, "facebook");
      if (response === null) {
        this.visibilityLoader = false;
        this.showAlert = true;
        this.paragraph = "Please wait a few minutes before you try again.";
      } else {
        this.dataTopPost = [];
        this.dataAudience = [];
        this.dataTable = [];
        const { data } = response;
        if (data === null) this.visibilityLoader = false;
        else this.sendDataToComponents(data);
      }
    },
    sendDataToComponents(data) {
      // Top Post
      this.dataTopPost = data.top_3_posts_interactions;
      // Tabla
      data.stats_top_authors_by_interactions.buckets.sort((a, b) => {
        if (a.summ_interactions.value < b.summ_interactions.value) return 1;
        if (a.summ_interactions.value > b.summ_interactions.value) return -1;
        return 0;
      });
      this.dataTable = data.stats_top_authors_by_interactions.buckets;
      // Top 3 audience growth
      this.dataTable.map(dt => {
        if (dt.authorFollowers != null)
          this.dataAudience.push(dt.authorFollowers);
      });
      if (this.dataAudience.length != 0)
        this.dataAudience = this.dataAudience.slice(0, 3);
      else this.dataTable = [];
      this.visibilityLoader = false;
    },
    resultModalAlerts(value) {
      this.showAlert = value;
    }
  }
};
</script>
