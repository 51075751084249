const _APIKEY = process.env.VUE_APP_API_KEY;
const _TOKEN = sessionStorage.getItem("token");
const _REFRESH_TOKEN = sessionStorage.getItem("refreshToken");
const _IP_PUBLIC = localStorage.getItem("ip-public");
const _IP_LOCAL = localStorage.getItem("ip-local");
export const getLocalStorage = {
  Authorization: `Bearer ${_TOKEN}|${_REFRESH_TOKEN}|${_IP_PUBLIC}|${_IP_LOCAL}`,
  api_key: _APIKEY
};

export function setToken(newToken) {
  sessionStorage.setItem("token", newToken);
}
