var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", [
    _vm.posts.length === 0
      ? _c("div", { staticClass: "container image-container-off" }, [
          _vm.network === "fb"
            ? _c("div", { staticClass: "w-100" }, [
                _c("img", {
                  staticClass: "img-default img-default-top-post",
                  attrs: {
                    src: require("../../../assets/img/off-top-post.png")
                  }
                })
              ])
            : _vm.network === "tw"
            ? _c("div", { staticClass: "w-100" }, [
                _c("img", {
                  staticClass: "img-default img-default-top-post",
                  attrs: {
                    src: require("../../../assets/img/top-post-default-twitter.png")
                  }
                })
              ])
            : _vm._e(),
          _c("div", { staticClass: "image-container-off__message" }, [
            _c("div", [_vm._v(_vm._s(_vm.titleImgOff))]),
            _vm._v(" " + _vm._s(_vm.msgImgOff) + " ")
          ])
        ])
      : _c(
          "div",
          { staticClass: "container" },
          _vm._l(_vm.posts, function(post, key) {
            return _c("div", { key: key, staticClass: "container_post" }, [
              _c("div", { staticClass: "container_post-body" }, [
                _c("div", { staticClass: "container_post-body_account" }, [
                  _c(
                    "div",
                    {
                      staticClass: "d-flex container_post-body_account-imgText"
                    },
                    [
                      _c(
                        "div",
                        { staticClass: "container_post-body_account-profile" },
                        [
                          _c(
                            "a",
                            {
                              attrs: { href: post.linkPerfil, target: "_blank" }
                            },
                            [
                              _c("b-img", {
                                staticClass:
                                  "container_post-body_account-image",
                                attrs: {
                                  fluid: "",
                                  src:
                                    post._source.author_fullname_photo ||
                                    post._source.author_photo ||
                                    _vm.photoId(
                                      post._source?.source_fanpage_id,
                                      _vm.network
                                    ) ||
                                    _vm.imageAccount
                                },
                                on: {
                                  error: function($event) {
                                    return _vm.errorUrlImage(
                                      $event,
                                      _vm.network,
                                      post._source?.source_fanpage_id
                                    )
                                  }
                                }
                              })
                            ],
                            1
                          )
                        ]
                      ),
                      _c(
                        "div",
                        { staticClass: "container_post-body_account-style" },
                        [
                          _c(
                            "div",
                            {
                              staticClass:
                                "container_post-body_account-style_publication text-bold"
                            },
                            [
                              _vm._v(
                                " " +
                                  _vm._s(
                                    post._source.author_fullname ||
                                      post._source.author_screen_name
                                  ) +
                                  " "
                              )
                            ]
                          ),
                          _c(
                            "div",
                            {
                              staticClass:
                                "container_post-body_account-style_publication d-flex"
                            },
                            [
                              _c(
                                "div",
                                {
                                  staticClass:
                                    "container_post-body_account-style_publication-arroba"
                                },
                                [
                                  _vm._v(
                                    " " + _vm._s(post._source.isc_driver) + " "
                                  )
                                ]
                              ),
                              _c("div", [
                                _vm._v(
                                  _vm._s(
                                    _vm.convertDate(
                                      post._source.sample_created_at
                                    )
                                  )
                                )
                              ])
                            ]
                          )
                        ]
                      )
                    ]
                  ),
                  _c(
                    "div",
                    { staticClass: "container_post-body_account-order" },
                    [_c("p", [_c("span", [_vm._v(_vm._s(key + 1))])])]
                  )
                ]),
                _c("div", { staticClass: "container_post-body_style" }, [
                  _c(
                    "a",
                    {
                      staticClass: "container_post-body_style",
                      attrs: {
                        target: "_blank",
                        href: post._source.sample_link
                      }
                    },
                    [
                      _vm._v(
                        _vm._s(
                          post._source.sample_text
                            ? post._source.sample_text.substring(0, 80) + "..."
                            : "-"
                        )
                      )
                    ]
                  )
                ]),
                _c("div", [
                  _vm.network === "fb"
                    ? _c(
                        "div",
                        [
                          _c("b-img", {
                            staticClass: "container_post-body_image",
                            attrs: {
                              fluid: "",
                              src: post._source.sample_image
                            },
                            on: {
                              error: _vm.errorUrlPost,
                              load: _vm.successUrlPost
                            }
                          })
                        ],
                        1
                      )
                    : _vm.network === "tw"
                    ? _c("div", [
                        post._source.sample_picture_url.length !== 0
                          ? _c(
                              "div",
                              [
                                _c("b-img", {
                                  staticClass: "container_post-body_image",
                                  attrs: {
                                    fluid: "",
                                    src: post._source.sample_picture_url
                                  },
                                  on: {
                                    error: _vm.errorUrlPost,
                                    load: _vm.successUrlPost
                                  }
                                })
                              ],
                              1
                            )
                          : _vm._e()
                      ])
                    : _vm._e()
                ])
              ]),
              _c("div", { staticClass: "container_post-footer" }, [
                _c(
                  "div",
                  {
                    directives: [
                      {
                        name: "show",
                        rawName: "v-show",
                        value: _vm.views,
                        expression: "views"
                      }
                    ],
                    staticClass: "container_post-footer_row"
                  },
                  [
                    _vm._m(0, true),
                    _c("div", { staticClass: "footer-interaction-style" }, [
                      _vm._v(" " + _vm._s(post.views ? post.views : 0) + " ")
                    ])
                  ]
                ),
                _c(
                  "div",
                  {
                    directives: [
                      {
                        name: "show",
                        rawName: "v-show",
                        value: _vm.shared,
                        expression: "shared"
                      }
                    ],
                    staticClass: "container_post-footer_row"
                  },
                  [
                    _vm._m(1, true),
                    _c("div", { staticClass: "footer-interaction-style" }, [
                      _vm._v(
                        " " +
                          _vm._s(
                            post._source.sample_shares
                              ? post._source.sample_shares
                              : 0
                          ) +
                          " "
                      )
                    ])
                  ]
                ),
                _c(
                  "div",
                  {
                    directives: [
                      {
                        name: "show",
                        rawName: "v-show",
                        value: _vm.comments,
                        expression: "comments"
                      }
                    ],
                    staticClass: "container_post-footer_row"
                  },
                  [
                    _vm._m(2, true),
                    _c("div", { staticClass: "footer-interaction-style" }, [
                      _vm._v(
                        " " +
                          _vm._s(
                            post._source.sample_comments_count
                              ? post._source.sample_comments_count
                              : 0
                          ) +
                          " "
                      )
                    ])
                  ]
                ),
                _c(
                  "div",
                  {
                    directives: [
                      {
                        name: "show",
                        rawName: "v-show",
                        value: _vm.favorites,
                        expression: "favorites"
                      }
                    ],
                    staticClass: "container_post-footer_row"
                  },
                  [
                    _vm._m(3, true),
                    _c("div", { staticClass: "footer-interaction-style" }, [
                      _vm._v(
                        " " +
                          _vm._s(
                            post._source.sample_likes_count
                              ? post._source.sample_likes_count
                              : 0
                          ) +
                          " "
                      )
                    ])
                  ]
                ),
                _c(
                  "div",
                  {
                    directives: [
                      {
                        name: "show",
                        rawName: "v-show",
                        value: _vm.retweet,
                        expression: "retweet"
                      }
                    ],
                    staticClass: "container_post-footer_row"
                  },
                  [
                    _vm._m(4, true),
                    _c("div", { staticClass: "footer-interaction-style" }, [
                      _vm._v(
                        " " +
                          _vm._s(
                            post._source.sample_retweet_count
                              ? post._source.sample_retweet_count
                              : 0
                          ) +
                          " "
                      )
                    ])
                  ]
                ),
                _c(
                  "div",
                  {
                    directives: [
                      {
                        name: "show",
                        rawName: "v-show",
                        value: _vm.likes,
                        expression: "likes"
                      }
                    ],
                    staticClass: "container_post-footer_row"
                  },
                  [
                    _vm._m(5, true),
                    _c("div", { staticClass: "footer-interaction-style" }, [
                      _vm._v(
                        " " +
                          _vm._s(
                            post._source.sample_favorite_count
                              ? post._source.sample_favorite_count
                              : 0
                          ) +
                          " "
                      )
                    ])
                  ]
                ),
                _c(
                  "div",
                  {
                    directives: [
                      {
                        name: "show",
                        rawName: "v-show",
                        value: _vm.unlikes,
                        expression: "unlikes"
                      }
                    ],
                    staticClass: "container_post-footer_row"
                  },
                  [
                    _vm._m(6, true),
                    _c("div", { staticClass: "footer-interaction-style" }, [
                      _vm._v(
                        " " + _vm._s(post.dislikes ? post.dislikes : 0) + " "
                      )
                    ])
                  ]
                )
              ])
            ])
          }),
          0
        )
  ])
}
var staticRenderFns = [
  function() {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", [
      _c("i", { staticClass: "fa fa-eye text-gray" }),
      _c("span", { staticClass: "footer-interaction-style footer-style" }, [
        _vm._v("Views")
      ])
    ])
  },
  function() {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", [
      _c("i", { staticClass: "fa fa-share text-gray" }),
      _c("span", { staticClass: "footer-interaction-style footer-style" }, [
        _vm._v("Shared")
      ])
    ])
  },
  function() {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", [
      _c("i", { staticClass: "fa fa-comments text-gray" }),
      _c("span", { staticClass: "footer-interaction-style footer-style" }, [
        _vm._v("Comments")
      ])
    ])
  },
  function() {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", [
      _c("i", { staticClass: "far fa-heart text-gray" }),
      _c("span", { staticClass: "footer-interaction-style footer-style" }, [
        _vm._v("Favorites")
      ])
    ])
  },
  function() {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", [
      _c("i", { staticClass: "fa fa-retweet text-gray" }),
      _c("span", { staticClass: "footer-interaction-style footer-style" }, [
        _vm._v("Retweet")
      ])
    ])
  },
  function() {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", [
      _c("i", { staticClass: "fa fa-thumbs-up text-gray" }),
      _c("span", { staticClass: "footer-interaction-style footer-style" }, [
        _vm._v("Likes")
      ])
    ])
  },
  function() {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", [
      _c("i", { staticClass: "fa fa-thumbs-down text-gray" }),
      _c("span", { staticClass: "footer-interaction-style footer-style" }, [
        _vm._v("Dislikes")
      ])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }