<template>
  <div>
    <div v-if="posts.length === 0" class="container image-container-off">
      <div v-if="network === 'fb'" class="w-100">
        <img
          src="../../../assets/img/off-top-post.png"
          class="img-default img-default-top-post"
        />
      </div>
      <div v-else-if="network === 'tw'" class="w-100">
        <img
          src="../../../assets/img/top-post-default-twitter.png"
          class="img-default img-default-top-post"
        />
      </div>
      <div class="image-container-off__message">
        <div>{{ titleImgOff }}</div>
        {{ msgImgOff }}
      </div>
    </div>
    <div v-else class="container">
      <div v-for="(post, key) in posts" :key="key" class="container_post">
        <div class="container_post-body">
          <div class="container_post-body_account">
            <div class="d-flex container_post-body_account-imgText">
              <div class="container_post-body_account-profile">
                <a :href="post.linkPerfil" target="_blank">
                  <b-img
                    fluid
                    v-bind:src="
                      post._source.author_fullname_photo ||
                        post._source.author_photo ||
                        photoId(post._source?.source_fanpage_id, network) ||
                        imageAccount
                    "
                    class="container_post-body_account-image"
                    @error="
                      errorUrlImage(
                        $event,
                        network,
                        post._source?.source_fanpage_id
                      )
                    "
                  >
                  </b-img>
                </a>
              </div>
              <div class="container_post-body_account-style">
                <div
                  class="container_post-body_account-style_publication text-bold"
                >
                  {{
                    post._source.author_fullname ||
                      post._source.author_screen_name
                  }}
                </div>
                <div
                  class="container_post-body_account-style_publication d-flex"
                >
                  <div
                    class="container_post-body_account-style_publication-arroba"
                  >
                    {{ post._source.isc_driver }}
                  </div>
                  <div>{{ convertDate(post._source.sample_created_at) }}</div>
                </div>
              </div>
            </div>
            <div class="container_post-body_account-order">
              <p>
                <span>{{ key + 1 }}</span>
              </p>
            </div>
          </div>
          <div class="container_post-body_style">
            <a
              class="container_post-body_style"
              target="_blank"
              :href="post._source.sample_link"
              >{{
                post._source.sample_text
                  ? post._source.sample_text.substring(0, 80) + "..."
                  : "-"
              }}</a
            >
          </div>
          <div>
            <div v-if="network === 'fb'">
              <b-img
                fluid
                v-bind:src="post._source.sample_image"
                class="container_post-body_image"
                @error="errorUrlPost"
                @load="successUrlPost"
              >
              </b-img>
            </div>
            <div v-else-if="network === 'tw'">
              <div v-if="post._source.sample_picture_url.length !== 0">
                <b-img
                  fluid
                  v-bind:src="post._source.sample_picture_url"
                  class="container_post-body_image"
                  @error="errorUrlPost"
                  @load="successUrlPost"
                >
                </b-img>
              </div>
            </div>
          </div>
        </div>
        <div class="container_post-footer">
          <div v-show="views" class="container_post-footer_row">
            <div>
              <i class="fa fa-eye text-gray"></i>
              <span class="footer-interaction-style footer-style">Views</span>
            </div>
            <div class="footer-interaction-style">
              {{ post.views ? post.views : 0 }}
            </div>
          </div>
          <div v-show="shared" class="container_post-footer_row">
            <div>
              <i class="fa fa-share text-gray"></i>
              <span class="footer-interaction-style footer-style">Shared</span>
            </div>
            <div class="footer-interaction-style">
              {{ post._source.sample_shares ? post._source.sample_shares : 0 }}
            </div>
          </div>
          <div v-show="comments" class="container_post-footer_row">
            <div>
              <i class="fa fa-comments text-gray"></i>
              <span class="footer-interaction-style footer-style"
                >Comments</span
              >
            </div>
            <div class="footer-interaction-style">
              {{
                post._source.sample_comments_count
                  ? post._source.sample_comments_count
                  : 0
              }}
            </div>
          </div>
          <div v-show="favorites" class="container_post-footer_row">
            <div>
              <i class="far fa-heart text-gray"></i>
              <span class="footer-interaction-style footer-style"
                >Favorites</span
              >
            </div>
            <div class="footer-interaction-style">
              {{
                post._source.sample_likes_count
                  ? post._source.sample_likes_count
                  : 0
              }}
            </div>
          </div>
          <div v-show="retweet" class="container_post-footer_row">
            <div>
              <i class="fa fa-retweet text-gray"></i>
              <span class="footer-interaction-style footer-style">Retweet</span>
            </div>
            <div class="footer-interaction-style">
              {{
                post._source.sample_retweet_count
                  ? post._source.sample_retweet_count
                  : 0
              }}
            </div>
          </div>
          <div v-show="likes" class="container_post-footer_row">
            <div>
              <i class="fa fa-thumbs-up text-gray"></i>
              <span class="footer-interaction-style footer-style">Likes</span>
            </div>
            <div class="footer-interaction-style">
              {{
                post._source.sample_favorite_count
                  ? post._source.sample_favorite_count
                  : 0
              }}
            </div>
          </div>
          <div v-show="unlikes" class="container_post-footer_row">
            <div>
              <i class="fa fa-thumbs-down text-gray"></i>
              <span class="footer-interaction-style footer-style"
                >Dislikes</span
              >
            </div>
            <div class="footer-interaction-style">
              {{ post.dislikes ? post.dislikes : 0 }}
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "@isc/styleguide";
import { commonMixin } from "../../../mixins/commonMixin";
export default {
  name: "CardTopPost",
  mixins: [commonMixin],
  data() {
    return {
      imageAccount: "../../../assets/img/off-account_150x150.png"
    };
  },
  props: {
    posts: {
      type: Array,
      default: function() {
        return [];
      }
    },
    shared: {
      type: Boolean,
      default: false
    },
    likes: {
      type: Boolean,
      default: false
    },
    unlikes: {
      type: Boolean,
      default: false
    },
    comments: {
      type: Boolean,
      default: false
    },
    views: {
      type: Boolean,
      default: false
    },
    favorites: {
      type: Boolean,
      default: false
    },
    retweet: {
      type: Boolean,
      default: false
    },
    network: {
      type: String,
      default: ""
    }
  },
  computed: {
    ...mapGetters({
      msgImgOff: "global/helper/getImageOff",
      titleImgOff: "global/helper/getTitleImgOff"
    })
  },
  methods: {
    smalldatetime(date) {
      let day = new Date(date).getDate();
      let month = new Date(date).getMonth();
      let year = new Date(date).getFullYear();
      let hours = new Date(date).getHours();
      let minutes = new Date(date).getMinutes();
      let seconds = new Date(date).getSeconds();
      return `${day}/${month + 1}/${year} ${hours + 1}:${minutes +
        1}:${seconds + 1}`;
    },
    stringdatetime(tweetCreatedAt) {
      const now = new Date();
      const diff = now.getTime() - tweetCreatedAt;
      const minutes = Math.round(diff / 60000);

      let message = `Hace ${minutes} minutos`;

      if (minutes >= 60) {
        const hours = Math.floor(minutes / 60);
        message = `Hace ${hours} hora${hours > 1 ? "s" : ""}`;

        if (hours >= 24) {
          const days = Math.floor(hours / 24);
          message = `Hace ${days} día${days > 1 ? "s" : ""}`;
        }
      }
      return message;
    },
    convertDate(date) {
      switch (this.network) {
        case "fb":
          return this.smalldatetime(date);
        case "tw":
          return this.stringdatetime(date);
        default:
          break;
      }
    },

    errorUrlPost(event) {
      event.target.className = "none-post";
    },
    successUrlPost(event) {
      event.target.className = "container_post-body_image img-fluid pb1-post";
    }
  }
};
</script>
<style lang="scss" src="./CardTopPost.scss" scoped />
